:root {
    font-size: _font(size);
    font-weight: _font(weight);
    --bs-font-sans-serif: Gruppo;
    --bs-navbar-active-color: unset;
}

@keyframes blur-out {
    0% {
        filter: blur(0);
        opacity: 1;
    }
    100% {
        filter: blur(5px);
        opacity: 0;
    }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    -webkit-text-size-adjust: none;
    background-color: _palette(bg1);
}

mark {
    background-color: transparent;
    color: inherit;
}

input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input,
select,
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

html {
    scroll-behavior: smooth;
    font-family: _font(family) !important;
    font-size: 1em;
    position: relative;
    min-height: 100%;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

@-ms-viewport {
    width: device-width;
}

.overlay1 {
    position: relative;
    z-index: 1;
}

.overlay1::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: _palette(purple);
    //background: -webkit-linear-gradient(bottom, #b224ef, #7579ff);
    //background: -o-linear-gradient(bottom, #b224ef, #7579ff);
    //background: -moz-linear-gradient(bottom, #b224ef, #7579ff);
    //background: linear-gradient(bottom, #b224ef, #7579ff);
    opacity: 0.4;
}

.overlay1::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar {
    display: none;
}

.map-container {
    position: relative;
}

.background-01 {
    background-color: _palette(bg1);
}

.background-02 {
    background-color: _palette(bg2);
}

.container-white {
    background-color: _palette(white);
}

#cookieConsent {
    width: 100%;
    background-color: _palette(white);
    color: _palette(black);
    font-size: 1.3rem;
    display: none;
    position: fixed;
    bottom: 0;
    padding: 2rem 7rem;
    text-align: center;
    z-index: 1000;
}

#home {
    .image-item {
        background-size: cover;
        min-height: 80vh;
    }
}

#where,
#services,
#neuro,
#contact,
#media {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .section-title {
        padding-bottom: 2rem;
    }

    .card-title {
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-size: 1.3em;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    .card-text {
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-size: 1.3em;
    }

    .blockquote-footer {
        font-size: 2em;
        margin-top: 2rem;
        margin-bottom: 4rem;
        margin-left: 5rem;
        margin-right: 5rem;
    }
}

#where {
    > div > div {
        position: relative;
    }

    span {
        font-size: 1.1em;
        color: _theme(primary);
    }

    .card-body {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .card-title {
        background-color: _theme(primary);
        color: _palette(white);
        margin-top: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .card-text {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .navbar {
        background-color: unset;
        padding: 0;
        width: 100%;
    }

    .navbar-expand {
        justify-content: center;
    }

    .nav-link {
        padding-left: 0;
    }

    #endereco {
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    #social-media {
        margin-top: 2rem;
    }

    #instagram,
    #twitter,
    #facebook,
    #youtube,
    #whatsapp {
        color: _theme(primary);
        font-size: 2em;
        margin-right: 0.5rem;
    }
}

#services,
#contact {
    background-size: cover;
    background-attachment: fixed;
    color: _palette(white);

    .h4 {
        color: _palette(white);
        font-size: 2em;
        margin-bottom: 3rem;
    }
}

#neuro {
    min-height: 100vh;

    #card-img-01,
    #card-img-02,
    #card-img-03 {
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        min-height: 40vh;
    }

    #card-img-01 {
        background-image: url('../../images/older01.jpg');
    }

    #card-img-02 {
        background-image: url('../../images/children01.jpg');
    }

    #card-img-03 {
        background-image: url('../../images/psychologists01.png');
    }

    .card {
        transition: all 1s ease;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    .card:hover {
        transition: all 1s ease;
        -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
        transform: scale(1.3);
        z-index: 100;
    }

    .card::before {
        z-index: unset;
    }
}

#footer {
    background-color: _theme(primary);
    min-height: 10rem;

    .figure-caption {
        color: _palette(white);
        display: inherit;
    }

    p {
        margin-bottom: 0;
        margin-top: 0;
        color: _palette(white);
    }

    #instagram,
    #twitter,
    #facebook,
    #youtube,
    #whatsapp {
        color: _palette(white);
        font-size: 2em;
        margin-right: 0.5rem;
    }
}

#services {
    min-height: 100vh;
    background-image: url('../../images/bg02.jpg');
}

#contact {
    min-height: 50vh;
    background-image: url('../../images/bg03.jpg');

    textarea {
        height: 10rem;
    }

    button,
    .form-label {
        font-weight: 800;
    }

    .form-label,
    .form-control,
    .form-select {
        font-size: 1.3em;
        min-height: calc(3.5 * 1.3rem);
    }

    .form-floating {
        color: _palette(black);
        font-size: 1.3em;
    }

    .toast {
        font-size: 1.3em;
        font-weight: 800;

        .toast-header {
            color: _palette(white);
        }

        .toast-body {
            color: _palette(black);
        }

        .toast-error {
            background-color: _palette(red);
        }

        .toast-success {
            background-color: _palette(green);
        }
    }
}
