section {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

nav {
    background-color: _theme(primary);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;

    a,
    .nav-link,
    .navbar-toggler,
    .navbar-brand {
        color: _palette(lightgray);
        border: none;
        --bs-navbar-nav-link-padding-x: 2rem;
        --bs-navbar-nav-link-padding-y: 1rem;
        --bs-nav-link-padding-y: 1rem;
    }

    a:hover,
    .nav-link:hover,
    .navbar-toggler:hover,
    .navbar-brand {
        color: _palette(orange);
    }

    .nav-link:hover:after {
        color: _palette(lightgray);
    }

    .navbar-toggler-icon {
        background-image: unset;
    }

    &.navbar {
        padding: 1rem;
    }

    .nav-link {
        &.active,
        &.show {
            color: _palette(orange) !important;
        }
    }
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5 {
    &.title {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 700;
    }
}

.btn {
    font-size: 1.5em;
    letter-spacing: 2px;
}

.btn-primary,
.btn-primary:after,
.btn-primary:before {
    background-color: _theme(primary);
    border-color: _theme(primary);
}

.btn-primary:hover {
    background-color: _theme(secondary);
    border-color: _theme(secondary);
}

figcaption {
    svg {
        margin-left: 0.7rem;
        margin-right: 0.7rem;
    }
}

figcaption::before {
    content: ' ' !important;
}

.modal-dialog {
    margin-top: 0;
    margin-bottom: 0;

    .modal-header {
        background-color: _theme(primary);
        color: _palette(white);
        font-size: 1.5em;

        .btn-close {
            --bs-btn-close-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 384 512%27 fill=%27%23fff%27%3e%3cpath d=%27M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z%27/%3e%3c/svg%3e');
        }
    }

    p {
        font-size: 1.3em;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    #card-img-inner-01,
    #card-img-inner-02,
    #card-img-inner-03 {
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        min-height: 20vh;
    }

    #card-img-inner-01 {
        background-image: url('../../images/old_man_face_01.png');
    }

    #card-img-inner-02 {
        background-image: url('../../images/girl_with_pet_03.png');
    }

    #card-img-inner-03 {
        background-image: url('../../images/psychologists03.png');
    }
}
